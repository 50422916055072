import * as React from "react"
import providerImage from "../images/providervalue.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"

const blog3 = () => (
  <Layout>
    <SEO title="Do Your Providers Really Add Value?" />
    <div class="container col-lg-6">
      <article class="blog-post mt-3">
        <h2 class="blog-post-title">Do Your Providers Really Add Value?</h2>
        <p class="blog-post-meta">March 12 2021 by Paul</p>
        <img src={providerImage} class="card-img-top mb-3" alt="..." />
        <p>
          Obviously, an important question! Does your Relocation service
          provider truly do everything they can to deliver the best service,
          evolve your program, and make your life easier?
        </p>
        <p>
          This is one of a series of questions that I ask Global Mobility
          professionals who seek my advice when evaluating if they will go to
          RFP or retain their existing provider.
        </p>
        <p>
          To help people make educated decisions there is a need to understand
          the value of their existing provider and if better value can be
          achieved by a change! I take an approach that will look beyond the
          program metrics that a client can analyse. There are a series of
          questions which provide a deep insight into the strategic value a
          provider delivers and importantly how they value their client!
        </p>
        <p>
          Based on my years of experience the following are examples of the
          questions that I ask clients to help them with their evaluation.
        </p>

        <ol type="1">
          <li class="mb-2">
            How often does the provider bring new ideas and services that are
            relevant to them? Or do they find out that other approaches and
            solutions exist from other sources, for example from new entrants to
            the market? It may be that new trends or entrants to the market will
            provide an aspect of the service presently being provided by the
            existing relocation provider, more effectively or at a lower cost.
            So even though a solution may deliver value to a client, it could
            reduce the profits of the relocation service provider so they may
            not be keen to share the solution with their clients!
            <br />
            Depending on what new solutions and trends a provider brings to you
            shows if they are actively listening to you, aware of new industry
            challenges and solutions. Most importantly it demonstrates if they
            genuinely believe in doing what is best for you their client, to
            deliver the best value and cost by bringing such trends and
            solutions to you!
          </li>
          <li class="mb-2">
            Relocation companies typically have consulting practices with
            exceptionally talented people to assist their clients. They provide
            a much-needed resource for the Global Mobility community with
            numerous articles, white papers, seminars on all manner of mobility
            related topics. But are these talented people engaged with you as a
            resource to focus on your goals and objectives, step in with
            solutions when challenges arise? Or are they only used to generate
            the articles, white papers, blogs that get sent to you? Yes, a
            professionally written article on a subject of interest is useful,
            but the companies who utilise their consultancy talent to consult
            directly with you to achieve solutions are really delivering a
            higher level of value to you.
          </li>
        </ol>
        <p>Top Tips</p>
        <ul>
          <li>
            Take time to stand back from the day to day and ask key questions to
            determine what strategic value a provider has for you.
          </li>
          <li>
            Speak to your network, what are their experiences with the same or
            other providers so you can benchmark.
          </li>
          <li>
            Evaluate and understand what provider value you already have before
            moving towards an RFP.
          </li>
          <li>
            If the evaluation confirms there is low strategic value in a
            provider, then do not hold back and plan to make a change!
          </li>
        </ul>
        <p>
          Written by Paul Barnes, Director, Inspire Global Mobility Consulting.
        </p>
        <p>
          Email: <strong>paul@igmobility.com</strong> Mobile: +44 (0) 7534 565
          888
        </p>
      </article>
    </div>
  </Layout>
)

export default blog3
